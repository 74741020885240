import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Link } from '@chakra-ui/react'
import LinkReactRouter from './link'
import Slider from 'react-slick'

const Hero = ({ ...props }) => {
  const altFish = 'Limited edition Fish Pie only 250 available'
  const urlFish = 'https://mudfoods.com/products/luxury-fish-pie-470g'
  const urlJulySale = '/collections/savoury-pies-and-squiches'
  const altJulySale =
    'Summer Sale: Get 5% off 8 pies, 10% off 10 pies, and 15% off 12 pies. Sale extended until midnight, Monday, July 29th. Discounts apply to savory pies and squiches at checkout.'
  const altChorizo = 'Limited edition Chicken and Spanish Chorizo Pie'
  const urlChorizo = '/products/chicken-and-spanish-chorizo-pie'
  const altSummer = 'Relax This Summer with Our Scrumptious Pies and Squiches™!'
  const altMatch = "Don't forget your match day pies"
  const urlMatch = '/collections/pie-bundles'
  const urlPulled = '/products/pulled-pork-in-sticky-barbecue-sauce'
  const altPulled = 'Limited edition Sticky BBQ pork pie'
  const urlFathers = '/collections/fathers-day'
  const altFathers =
    'Forget the ties and socks—this year, let’s spoil Dad with something truly special! Treat your amazing dad, grandpa, stepdad, or father-in-law to our mouth-watering pies or a Mud Foods gift card. It’s a thoughtful gesture that shows how much you care and will mean so much more.'
  const urlSummer = '/collections/savoury-pies-and-squiches'
  const alt5for10 =
    '£5 off when you choose 10 pies or more - for 1 week only. Choose from a variety of delicious savory pies and quiches. Discount automatically applied at checkout.'
  const urlVege = 'collections/vegan-vegetarian-pies'
  const altVege =
    '15% Off All Vegetarian and Vegan Pies. Quiches, fruit pies, and side dishes. For one day only.'
  const altSpringLamb =
    "Image showing a 'Limited Edition Spring Lamb and Mint Pie reduced to £3.99'. Text highlights that it's only available for a short time."
  const urlSpringLamb = '/products/spring-lamb-and-mint-pie'
  const altSpringFair =
    "Event from Thursday, April 18th to Sunday, April 21st, 2024 at Sudeley Castle featuring Christian, Mud's Chief Pie Maker. Tickets £5. Visit ukgrandsales.co.uk for details."
  const altSteakAle2024 =
    'Special Offer: Award-Winning Steak & Ale Pie now only £3.99 until April 22nd.'
  const urlSteakAle2024 = '/products/steak-ale-pie'
  const altAldi2 =
    'WINNER OF ALDI THE NEXT BIG THING CHANNEL 4 - Chicken Chip Shop Chicken Curry Pie now only £3.99'
  const urlAldi2 = '/products/chip-shop-chicken-curry-pie'
  const urlEaster = '/collections/buy-pies-online'
  const altEaster =
    'The deliciously big Easter sale. 12 pies* for the price of 11 and free delivery for one week only. Get cracking. Offer ends midnight Monday 25th March.'
  const urlBFG = '/products/black-forest-gateau-pie-serves-2'
  const altBFG =
    'A limited edition Black Forest Gateau Pie that combines dark chocolate, black cherries, and cherry liqueur in an all-butter chocolate pastry, offering a gourmet twist on the classic dessert.'
  const urlTunworth = '/products/steak-and-tunworth-cheese-pie-270g'
  const altTunworth =
    'A luxurious, limited edition Steak and Tunworth Cheese Pie featuring premium grass-fed British steak, a rich Bechamel sauce with white wine and kirsch, and creamy Tunworth cheese, handcrafted for the discerning palate.'
  const altBirthday =
    "We're celebrating 15 years of pie-fection with 15% off all pies and squiches for one week only"
  const urlBirthday = '/collections/buy-pies-online'
  const urlhb = '/products/hearty-beef-and-potato-pie'
  const hbtitle = `Thank you Mud Foods for my delivery of meat and potato pies today. We had two for tea and they were beautiful - the pastry was delicious and the filling was absolutely full to the brim. Definitely be ordering again."r). Discount applied automatically. Free delivery on orders of £45 (and over),`
  const altRugby =
    'Experience the delectable taste of our top-selling products by trying a box of our finest offerings. They are truly scrumptious!'
  const urlRugby = 'collections/pie-bundles'
  const clickedBannerEvent = () => {
    if (
      process.env.NODE_ENV === `production` &&
      typeof _learnq.push === `function` // eslint-disable-line no-undef
    ) {
      // prettier-ignore
      _learnq.push([// eslint-disable-line no-undef
        
        'track',
        'Clicked Banner',
        {
          SourceURL: 'https://mudfoods.com/',
          DestinationURL: 'https://mudfoods.com' + urlhb,
          BannerTitle: hbtitle,
        },
      ])
    }
  }

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
  }
  const settingsMobile = {
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 0,
        settings: 'unslick',
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'inline-block !important',
          '@media screen and (min-width: 640px)': {
            display: 'none !important',
          },
        }}
      >
        <Slider {...settingsMobile}>
          <Link
            to={urlBirthday}
            key="flashBirthday"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/birthday/mobile/01.jpg"
              alt={altBirthday}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <Link
            to={urlFish}
            key="flashFish"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/fish/mobile/01.jpg"
              alt={altFish}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          <Link
            to={urlChorizo}
            key="flashChorizo"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/chorizo/mobile/01.jpg"
              alt={altChorizo}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          <Link
            to={urlPulled}
            key="flashPulled"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pulled/mobile/01.jpg"
              alt={altPulled}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          {/* <Link
            to={urlMatch}
            key="flashMatch"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/match/mobile/01.jpg"
              alt={altMatch}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          <Link
            to={urlSummer}
            key="summerday2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/summer-2024/mobile/01.jpg"
              alt={altSummer}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          {/* <Link
            to={urlBPA2024}
            key="flashBPW2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpa2024/mobile/01.jpg"
              alt={altBPA2024}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlTunworth}
            key="flashBPWTun2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/mobile/01.jpg"
              alt={altTunworth}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <Link
            to={urlBFG}
            key="flashBPWBFG2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/mobile/02.jpg"
              alt={altBFG}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          {/* <Link
            to={urlPasty}
            key="pasty2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pasty/mobile/01.jpg"
              alt={altPasty}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlRugby}
            key="Rugby2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/rugby/mobile/01.jpg"
              alt={altRugby}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
        </Slider>
      </Container>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'none !important',
          '@media screen and (min-width: 640px)': {
            display: 'inline-block !important',
          },
        }}
      >
        <Slider {...settingsMain}>
          <Link
            to={urlBirthday}
            key="BirthdayFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/birthday/desktop/01.jpg"
              alt={altBirthday}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <Link
            to={urlFish}
            key="FishFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/fish/desktop/01.jpg"
              alt={altFish}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          <Link
            to={urlChorizo}
            key="ChorizoFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/chorizo/desktop/01.jpg"
              alt={altChorizo}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          <Link
            to={urlPulled}
            key="PulledFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/pulled/desktop/01.jpg"
              alt={altPulled}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>

          {/* <Link
            to={urlMatch}
            key="MatchFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/match/desktop/01.jpg"
              alt={altMatch}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}

          <Link
            to={urlSummer}
            key="SummerDay1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/summer-2024/desktop/01.jpg"
              alt={altSummer}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          {/* <Link
            to={urlBPA2024}
            key="BPW1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpa2024/desktop/01.jpg"
              alt={altBPA2024}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
          {/* <Link
            to={urlTunworth}
            key="BPWTun1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/desktop/01.jpg"
              alt={altTunworth}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link>
          <Link
            to={urlBFG}
            key="BPWBFG1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/bpw-products/desktop/02.jpg"
              alt={altBFG}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}

          {/* <Link
            to={urlRugby}
            key="Rugby1"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/rugby/desktop/01.jpg"
              alt={altRugby}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
            />
          </Link> */}
        </Slider>
      </Container>
    </>
  )
}

export default Hero
